<template>
  <div class="overlay" v-if="isShown">
    <div class="wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      isShown: false,
    };
  },
  methods: {
    closeModal() {
      this.isShown = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.isShown = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  },
};
</script>

<style>
.overlay {
  @apply z-40 overflow-auto flex items-center bg-gray-700 left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50;
}
.wrapper {
  @apply relative mx-auto z-50 max-w-lg bg-white rounded-2xl;
}
</style>
