<template>
  <modal ref="popup">
    <div class="p-5 text-center text-xs">
      <slot name="content"></slot>
      <div class="flex flex-row justify-around">
        <slot name="controls"></slot>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "./core/Modal.vue";
import TButton from "./core/Button.vue";

export default {
  name: "ConfirmDialogue",
  components: {
    Modal,
    TButton,
  },
  data() {
    return {
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    show() {
      this.$refs.popup.openModal();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.$refs.popup.closeModal();
      this.resolvePromise(false);
    },
    _confirm() {
      this.$refs.popup.closeModal();
      this.resolvePromise(true);
    },
  },
};
</script>
