<template>
  <div
    class="container max-w-screen-xl lg:mx-auto md:px-4 flex flex-row flex-wrap w-full my-6"
  >
    <div class="w-3/12">
      <slot name="controls"></slot>
    </div>

    <div class="w-9/12">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "DLayout",
};
</script>
