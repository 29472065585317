<template>
  <div>
    <d-layout class="mt-20">
      <template #controls>
        <div
          class="w-full bg-white flex flex-col gap-2 rounded-2xl mt-9 py-2 px-8"
        >
          <router-link class="text-base font-bold" to="/dashboard">
            Timers
          </router-link>
          <router-link class="text-base font-bold" to="/account">
            Account
          </router-link>
        </div>
      </template>
      <template #content>
        <div class="ml-4">
          <div class="flex items-center justify-between">
            <div></div>
            <p class="text-xl font-bold text-center text-black">
              My Timers
            </p>
            <t-button
              class="text-white bg-blue-700 hover:bg-blue-800 focus:bg-blue-900"
              tag="router-link"
              to="/"
            >
              + New Timer
            </t-button>
          </div>
          <div class="w-full bg-white rounded-2xl mt-2 p-5">
            <template v-if="isLoading">
              <spinner color="text-green" size="md" />
            </template>
            <template v-else>
              <div
                class="flex flex-row justify-between text-xs text-black items-center  border-b border-gray-200"
                v-for="(t, key) in sortedTimers"
                :key="key"
              >
                <div class="flex flex-row justify-items-start gap-20 w-full">
                  <span class="w-1/6">
                    <p class="font-bold">{{ t.name }}</p>
                  </span>
                  <span>
                    <p>{{ formatToLocal(t.date_end) }}</p>
                  </span>
                </div>

                <div class="flex flex-row">
                  <t-button inlineDanger @click.native="handleDelete(t.id)"
                    >Delete</t-button
                  >
                  <t-button
                    inlinePrimary
                    tag="router-link"
                    :to="{ name: 'Edit', params: { timerId: t.id } }"
                    @click.native="pushToEdit(t.id)"
                    >Edit
                  </t-button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </d-layout>
    <confirm-dialogue ref="confirmDialogue">
      <template #content>
        <span>
          <p class="">
            Are you sure you want to <strong>DELETE</strong> this timer?
          </p>
        </span>

        <p class="mt-2">This action cannot be undone</p>
      </template>
      <template #controls>
        <t-button
          secondary
          class="mt-4"
          @click.native="$refs.confirmDialogue._cancel()"
          >CANCEL</t-button
        >
        <t-button
          danger
          class="mt-4"
          @click.native="$refs.confirmDialogue._confirm()"
          >DETELE</t-button
        >
      </template>
    </confirm-dialogue>
  </div>
</template>

<script>
import DLayout from "../layouts/DLayout.vue";
import TButton from "@/components/core/Button.vue";
import Spinner from "@/components/core/Spinner.vue";
import ConfirmDialogue from "../components/ConfirmDialogue.vue";

import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    DLayout,
    TButton,
    Spinner,
    ConfirmDialogue,
  },
  async mounted() {
    await this.getTimers();
  },
  computed: {
    ...mapGetters({
      timers: "timers/allTimers",
      isLoading: "timers/isLoading",
    }),
    sortedTimers: function() {
      this.timers.sort((a, b) => {
        return new Date(b.date_end) - new Date(a.date_end);
      });
      return this.timers;
    },
  },
  methods: {
    getTimers() {
      this.$store.dispatch("timers/getTimers");
    },
    pushToEdit(timerId) {
      const timer = this.timers.find((timer) => timer.id === timerId);
      this.$store.commit("timers/SET_TIMER", timer);
    },
    async handleDelete(timerId) {
      const confirm = await this.$refs.confirmDialogue.show();

      if (confirm) {
        await this.$store.commit("timers/DROP_FROM_TIMERS", timerId);
        await this.$store.dispatch("timers/removeTimer", timerId);
      }
    },
    formatToLocal(timestamp) {
      if (timestamp !== null && timestamp !== "") {
        return new Date(timestamp).toLocaleString();
      } else {
        return "";
      }
    },
  },
};
</script>
