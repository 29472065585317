var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"component",staticClass:"btn",class:[
    { primary: _vm.primary },
    { secondary: _vm.secondary },
    { outline: _vm.outline },
    { danger: _vm.danger },
    { link: _vm.link },
    { 'inline-danger': _vm.inlineDanger },
    { 'inline-primary': _vm.inlinePrimary },
    { disabled: _vm.disabled } ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},'component',_vm.$attrs,false),[(_vm.loading)?_c('spinner',{attrs:{"color":"text-white","size":"sm"}}):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }