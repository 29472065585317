<template>
  <component
    :is="tag"
    :disabled="disabled"
    v-bind="$attrs"
    @click="$emit('click', $event)"
    class="btn"
    :class="[
      { primary: primary },
      { secondary: secondary },
      { outline: outline },
      { danger: danger },
      { link: link },
      { 'inline-danger': inlineDanger },
      { 'inline-primary': inlinePrimary },
      { disabled: disabled },
    ]"
  >
    <spinner v-if="loading" color="text-white" size="sm" />
    <slot v-else></slot>
  </component>
</template>

<script>
import Spinner from "@/components/core/Spinner.vue";

export default {
  name: "TButton",
  components: {
    Spinner,
  },
  props: {
    tag: {
      type: String,
      default: "button",
    },
    primary: Boolean,
    secondary: Boolean,
    outline: Boolean,
    danger: Boolean,
    link: Boolean,
    inlineDanger: Boolean,
    inlinePrimary: Boolean,
    disabled: Boolean,
    loading: Boolean,
  },
};
</script>

<style scoped>
.btn {
  @apply block
  px-4 py-0.5
  text-sm font-medium tracking-wide
  text-center
  duration-100
  ease-in-out
  rounded;
}

.primary {
  @apply bg-green text-white hover:bg-green-600 focus:bg-green-700;
}

.secondary {
  @apply bg-gray-200 hover:bg-gray-400 focus:bg-gray-500;
}

.outline {
  @apply bg-transparent
    border-solid border-2 py-0
    border-green
    text-green
    hover:bg-green hover:text-white
    focus:bg-green focus:text-white;
}

.danger {
  @apply bg-red-600 text-white hover:bg-red-700 focus:bg-red-800;
}

.link {
  @apply text-xs px-2 text-gray-400 hover:text-gray-500 focus:text-gray-600;
}

.inline-primary {
  @apply text-green text-xs font-semibold hover:text-green-600 focus:text-green-700;
}

.inline-danger {
  @apply text-red-500 text-xs font-semibold hover:text-red-600 focus:text-red-700;
}

.disabled {
  @apply opacity-50 cursor-not-allowed;
}

.animate-none {
  animation: none;
}

.animate-spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
